<plex-layout main="8">
    <plex-layout-main>
        <ng-container>
            <plex-title titulo="Calendario de Agendas" size="sm">
                <plex-button class="mx-2" type="info" size="sm" icon="chevron-left" (click)="cambiarMes('-')">
                </plex-button>
                <span class="text-120">{{ opciones.fecha | date: "MMM yyyy" | uppercase }}</span>
                <plex-button class="ml-1" size="sm" type="info" icon="chevron-right" (click)="cambiarMes('+')">
                </plex-button>
                <plex-button label="Lista de espera" size="sm" class="ml-2 invisible" type="warning"
                             (click)="noSeAsignaTurno()" [disabled]="!opciones.tipoPrestacion">
                </plex-button>
                <plex-button class="ml-1" label="Volver" size="sm" type="danger" (click)="volver()">
                </plex-button>
            </plex-title>
            <plex-grid size="lg" type="full">
                <plex-wrapper>
                    <plex-select *ngIf="!_solicitudPrestacion?.solicitud && !tipoPrestacionesPermitidas"
                                 [(ngModel)]="opciones.tipoPrestacion" (getData)="loadTipoPrestaciones($event)"
                                 label="Tipos de Prestación" (change)="filtrar()" name="tipoPrestacion">
                    </plex-select>
                    <plex-select *ngIf="_solicitudPrestacion?.solicitud?.tipoPrestacion && !tipoPrestacionesPermitidas"
                                 [(ngModel)]="_solicitudPrestacion.solicitud.tipoPrestacion"
                                 (getData)="loadTipoPrestaciones($event)" label="Tipos de Prestación"
                                 (change)="filtrar()" name="tipoPrestacion" [readonly]="true">
                    </plex-select>

                    <plex-select *ngIf="tipoPrestacionesPermitidas" [(ngModel)]="opciones.tipoPrestacion"
                                 [data]="tipoPrestacionesPermitidas" label="Tipos de Prestación" (change)="filtrar()"
                                 name="tipoPrestacion">
                    </plex-select>

                    <plex-select *ngIf="!_solicitudPrestacion?.solicitud" [(ngModel)]="opciones.profesional"
                                 (getData)="loadProfesionales($event)" label="Equipo de Salud"
                                 labelField="apellido + ' ' + nombre" (change)="filtrar()" name="profesional">
                    </plex-select>
                    <plex-select *ngIf="_solicitudPrestacion?.solicitud?.profesional && autocitado" label="Profesional"
                                 [(ngModel)]="_solicitudPrestacion.solicitud.profesional"
                                 (getData)="loadProfesionales($event)" labelField="apellido + ' ' + nombre"
                                 (change)="filtrar()" name="profesional">
                    </plex-select>
                    <plex-select *ngIf="(_solicitudPrestacion?.solicitud && !autocitado) " label="Profesional"
                                 [(ngModel)]="opciones.profesional" (getData)="loadProfesionales($event)"
                                 labelField="apellido + ' ' + nombre" (change)="filtrar()" name="profesional">
                    </plex-select>
                    <div *ngIf="mostrarCalendario" class="d-flex flex-column justify-content-center align-items-center">
                        <plex-bool [(ngModel)]="mostrarNoDisponibles" type="slide" name="mostrarNoDisponibles"
                                   label="Agendas sin turnos"
                                   title="{{mostrarNoDisponibles ? 'Ocultar agendas sin turnos' : 'Mostrar agendas sin turnos'}}"
                                   (change)="actualizar()"></plex-bool>
                        <plex-bool [(ngModel)]="mostrarFinesDeSemana" type="slide" name="mostrarFinesDeSemana"
                                   label="Sábados y domingos"
                                   title="{{mostrarFinesDeSemana ? 'Mostrar sábados y domingos' : 'Ocultar sábados y domingos'}}"
                                   (change)="actualizar()">
                        </plex-bool>
                    </div>
                </plex-wrapper>
            </plex-grid>
            <div *ngIf="!mostrarCalendario">
                Por favor elija una prestación para mostrar las agendas disponibles
            </div>
            <!--Calendario-->
            <app-calendario *ngIf="mostrarCalendario" [agendas]="agendas" [fecha]="opciones.fecha" [agenda]="agenda"
                            [estado]="estadoT" [opcionesCalendario]="!mostrarFinesDeSemana"
                            [mostrarNoDisponibles]="mostrarNoDisponibles" [_solicitudPrestacion]="_solicitudPrestacion"
                            [filtroPrestacion]="opciones.tipoPrestacion" [tipoTurno]="tipoTurno"
                            (agendaChanged)="seleccionarAgenda($event)">
            </app-calendario>
        </ng-container>
    </plex-layout-main>
    <plex-layout-sidebar type="invert">
        <plex-title *ngIf="paciente && estadoT === 'noSeleccionada'" titulo="Paciente" size="sm"></plex-title>
        <ng-container *ngIf="paciente && estadoT === 'noSeleccionada'">
            <paciente-detalle [paciente]="paciente" orientacion="horizontal" [fields]="pacienteFields">
            </paciente-detalle>
        </ng-container>
        <plex-title *ngIf="estadoT === 'noSeleccionada' && !_solicitudPrestacion?.solicitud"
                    titulo="Búsquedas recientes" size="sm"></plex-title>
        <plex-list *ngIf="estadoT === 'noSeleccionada' && !_solicitudPrestacion?.solicitud" [selectable]="true">
            <plex-item *ngFor="let busqueda of busquedas; let i = index" (click)="seleccionarBusqueda(i)">
                <plex-label *ngIf="busqueda.tipoPrestacion" titulo="{{busqueda.tipoPrestacion.term}}">
                </plex-label>
                <plex-label *ngIf="busqueda.profesional " titulo="{{busqueda.profesional | nombre}}">
                </plex-label>
            </plex-item>
        </plex-list>
        <ng-container *ngIf="cumpleEstados()">
            <plex-title titulo="Dar turno" size="sm"></plex-title>
            <div class="contenedor-slider">
                <plex-button align="middle" tooltip="Agenda anterior" name="agendaAnterior" class="btn-prev" size="sm"
                             type="primary" (click)="verAgenda('izq')"
                             [disabled]="agendasDelDia && !agendasDelDia[indice-1]">
                    <plex-icon justify="center" size="md" name="pico-izquierda"></plex-icon>
                </plex-button>
                <div class="contenido-agenda">
                    <plex-grid type="full" cols="2" justify="start">
                        <plex-badge type="info">
                            AGENDA {{indice+1}} /{{agendasDelDia.length}}
                        </plex-badge>
                        <plex-badge type="warning" class="mx-1" tooltipPosition="top" tooltip="Turnos agenda">
                            {{agendasDelDia[indice].turnosDisponibles}}
                            <plex-icon name="calendario-fecha" size="md"></plex-icon>
                        </plex-badge>
                        <plex-badge *ngIf="agenda.link" type="info" tooltipPosition="top" tooltip="Agenda Virtual">
                            <plex-icon name="monitor" size="md"></plex-icon>
                        </plex-badge>
                        <plex-button *ngIf="puedeDarSobreturno && estadoT !== 'dinamica'" class="mr-1" type="success"
                                     size="sm" icon="account-plus" (click)="agregarSobreturno()" tooltipPosition="top"
                                     tooltip="Agregar Sobreturno">
                        </plex-button>
                        <plex-help titulo="" subtitulo="" type="help" size="sm" icon="information-variant">
                            <plex-label size="md" titulo="Prestación"
                                        subtitulo="{{agenda.tipoPrestaciones | enumerar:['term']}}">
                            </plex-label>
                            <plex-label *ngIf="agenda.profesionales?.length" size="md" titulo="Equipo de Salud"
                                        subtitulo="{{agenda.profesionales | enumerar:['apellido','nombre']}}">
                            </plex-label>
                            <plex-label *ngIf="agenda.profesionales?.length === 0" size="md" titulo="Equipo de Salud"
                                        subtitulo="Equipo de Salud no asignado">
                            </plex-label>
                            <plex-label *ngIf="agenda.espacioFisico" size="md" titulo="Espacio Físico"
                                        subtitulo="{{agenda | espacioFisico}}">
                            </plex-label>
                            <plex-label *ngIf="!agenda.espacioFisico" size="md" titulo="Espacio Físico"
                                        subtitulo="Espacio físico no asignado">
                            </plex-label>
                        </plex-help>
                    </plex-grid>
                    <plex-grid type="full" justify="start">
                        <plex-grid type="full" cols="2" justify="center">
                            <plex-label size="md" case="capitalize"
                                        titulo="{{ agenda.horaInicio | date: 'EEEE d/M/yyyy' }}"
                                        subtitulo="{{agenda.horaInicio | date: 'HH:mm'}} - {{agenda.horaFin | date: 'HH:mm'}}hs">
                            </plex-label>

                            <plex-grid *ngIf="opciones.profesional && !opciones.tipoPrestacion" class="m-0" type="full"
                                       justify="center">
                                <plex-label size="md" titulo="Prestación"
                                            subtitulo="{{agenda.tipoPrestaciones | enumerar:['nombre']}}">
                                </plex-label>
                            </plex-grid>
                            <plex-grid *ngIf="!opciones.profesional && opciones.tipoPrestacion" class="m-0" type="full"
                                       justify="center">
                                <plex-label *ngIf="agenda.profesionales?.length" size="md" titulo="Equipo de Salud"
                                            subtitulo="{{agenda.profesionales | enumerar:['apellido','nombre']}}">
                                </plex-label>
                                <plex-label *ngIf="agenda.profesionales?.length === 0" size="md"
                                            titulo="Equipo de Salud" subtitulo="Equipo de Salud no asignado">
                                </plex-label>
                            </plex-grid>
                            <plex-grid *ngIf="opciones.profesional && opciones.tipoPrestacion" class="m-0" type="full"
                                       justify="center">
                                <plex-label size="md" titulo="Prestación" subtitulo="{{opciones.tipoPrestacion.term}}">
                                </plex-label>
                                <plex-label size="md" titulo="Equipo de Salud"
                                            subtitulo="{{opciones.profesional.apellido}}, {{opciones.profesional.nombre}}">
                                </plex-label>
                            </plex-grid>
                        </plex-grid>
                    </plex-grid>
                </div>
                <plex-button align="middle" tooltip="Agenda siguiente" tooltipPosition="left" name="agendaSiguiente"
                             class="btn-next" size="sm" type="primary" (click)="verAgenda('der')"
                             [disabled]="agendasDelDia && !agendasDelDia[indice+1]">
                    <plex-icon justify="center" size="md" name="pico-derecha"></plex-icon>
                </plex-button>
            </div>

            <!--Paciente detalle -->
            <paciente-detalle [paciente]="paciente" orientacion="horizontal" size="xs" [fields]="pacienteFields">
            </paciente-detalle>
            <div *ngIf="showSobreturno">
                <dar-sobreturno (volver)="volver()" [idAgenda]="agenda.id" [idPaciente]="paciente.id"></dar-sobreturno>
            </div>
            <div *ngIf="!showSobreturno">
                <!-- Tiene turnos disponibles -->
                <ng-container *ngIf="tieneTurnosDisponible()">
                    <!--Lista de turnos, bloque por bloque-->
                    <plex-title size="sm" titulo="Turnos disponibles para este bloque"></plex-title>
                    <plex-grid class="turnos_disponibles" responsive type="full" cols="4"
                               *ngFor="let bloque of bloques">
                        <plex-label align="center" size="md" titulo="Del día"
                                    subtitulo="{{countBloques[findIndex(bloque)]?.delDia}}">
                        </plex-label>
                        <plex-label align="center" size="md" titulo="Programados"
                                    subtitulo="{{countBloques[findIndex(bloque)]?.programado}}">
                        </plex-label>
                        <plex-label align="center" size="md" titulo="Con llave"
                                    subtitulo="{{countBloques[findIndex(bloque)]?.gestion}}">
                        </plex-label>
                        <plex-label align="center" size="md" titulo="Profesional"
                                    subtitulo="{{countBloques[findIndex(bloque)]?.profesional}}">
                        </plex-label>
                    </plex-grid>
                </ng-container>

                <!-- Dependiendo el estado, se visualiza el plex-title aducuado -->
                <plex-title titulo="{{estadoT==='dinamica'?'Agenda dinámica':estadoT==='noTurnos'?'Agendas alternativas':'Selección horario'}}"
                            size="sm">
                    <ng-container *ngIf="estadoT !== 'noTurnos'">
                        <plex-bool *ngIf="permitirTurnoDoble" class="mr-2 text-secondary" label="Turno doble"
                                   [(ngModel)]='turnoDoble' name="turnoDoble">
                        </plex-bool>
                        <plex-bool *ngIf="showTurnoTelefonico()" label="Turno telefónico" [(ngModel)]='turnoTelefonico'
                                   name="turnoTelefonico">
                        </plex-bool>
                    </ng-container>
                </plex-title>

                <!-- Agenda con turnos disponibles -->
                <ng-container *ngIf="estadoT === 'seleccionada'">
                    <!--Lista de turnos, bloque por bloque-->
                    <div class="listado-turnos mb-4" *ngFor="let bloque of bloques">
                        <plex-label *ngIf="!bloque.descripcion" titulo="Descripción: -"></plex-label>
                        <plex-label *ngIf="bloque.descripcion" titulo="Descripción: {{bloque.descripcion}}">
                        </plex-label>
                        <plex-grid *ngIf="opciones.profesional && !opciones.tipoPrestacion"
                                   class="turnos_disponibles border border-white" responsive type="full" cols="4">
                            <plex-label align="center" size="md" titulo="Del día"
                                        subtitulo="{{countBloques[findIndex(bloque)]?.delDia}}">
                            </plex-label>
                            <plex-label align="center" size="md" titulo="Programados"
                                        subtitulo="{{countBloques[findIndex(bloque)]?.programado}}">
                            </plex-label>
                            <plex-label align="center" size="md" titulo="Con llave"
                                        subtitulo="{{countBloques[findIndex(bloque)]?.gestion}}">
                            </plex-label>
                            <plex-label align="center" size="md" titulo="Profesional"
                                        subtitulo="{{countBloques[findIndex(bloque)]?.profesional}}">
                            </plex-label>
                        </plex-grid>
                        <plex-grid *ngIf="tieneTurnos(bloque)" type="full" size="sm">
                            <plex-card type="dark" mode="filled" size="xs"
                                       *ngFor="let turno of bloque.turnos; let i=index" selectable="true"
                                       [selected]="isActive(turno)" (click)="seleccionarTurno(bloque,i)"
                                       [ngClass]="{'disabled' : turno.estado !== 'disponible'}">
                                <plex-label *ngIf="turno.estado === 'disponible'" size="md" direction="column"
                                            titulo="{{turno.horaInicio | date: 'HH:mm'}}hs">
                                </plex-label>
                                <plex-label *ngIf="turno.estado !== 'disponible'" size="md" direction="column"
                                            titulo="{{turno.horaInicio | date: 'HH:mm'}}hs"
                                            aria-label="turno no disponible">
                                </plex-label>
                            </plex-card>
                        </plex-grid>
                    </div>
                </ng-container>

                <!--Si no hay turnos disponibles, se muestran alternativas-->
                <h6 *ngIf="verInexistenciaAlternativas()">No existen alternativas</h6>
                <h6 *ngIf="estadoT == 'noTurnos' && reqfiltros">Debe ingresar algún filtro para buscar alternativa</h6>
                <h6 *ngIf="estadoT == 'noTurnos' && alternativas.length > 0">No hay turnos en esta agenda,
                    <strong>seleccione una agenda alternativa</strong>
                </h6>

                <plex-list *ngIf="estadoT == 'noTurnos' && alternativas.length > 0" [selectable]="true">
                    <plex-item *ngFor="let alternativa of alternativas; let i=index"
                               (click)="seleccionarAlternativa(i)">
                        <plex-label titulo="Fecha" subtitulo="{{alternativa.horaInicio | fecha}}" size="md">
                        </plex-label>
                        <plex-label titulo="Tipo de Prestación"
                                    subtitulo="{{alternativa.tipoPrestaciones | enumerar:['nombre']}}" size="md">
                        </plex-label>
                        <plex-label *ngIf="alternativa.profesionales.length" titulo="Equipo de Salud"
                                    subtitulo="{{alternativa.profesionales | enumerar:['apellido','nombre']}}"
                                    size="md">
                        </plex-label>
                        <plex-label *ngIf="alternativa.profesionales.length === 0" titulo="Equipo de Salud"
                                    subtitulo="Equipo de Salud no asignado" size="md">
                        </plex-label>
                    </plex-item>
                </plex-list>

                <!--Agenda Dinámica -->
                <plex-list *ngIf="estadoT == 'dinamica'" size="md" [selectable]="false">
                    <plex-item>
                        <plex-button *ngIf="agendaDeHoy()" label="Dar Turno" type="success" (click)="turnoDinamico()"
                                     size="sm">
                        </plex-button>
                        <plex-badge *ngIf="agenda.cupo > 1" type="info">{{agenda.cupo}} cupos disponibles
                        </plex-badge>
                        <plex-badge *ngIf="agenda.cupo === 1" type="info">{{agenda.cupo}} cupo disponible
                        </plex-badge>
                        <plex-badge *ngIf="agenda.cupo === -1" type="info">Agenda con cupos no limitados
                        </plex-badge>
                    </plex-item>
                    <div *ngIf="!agendaDeHoy()" justify="center" class="mt-3 mb-3">
                        <plex-label class="flex-column" icon="calendar" size="md" direction="column"
                                    titulo="Solo se puede dar turno el {{ agenda.horaInicio | date: 'EEEE d/M/yyyy' }}">
                        </plex-label>
                    </div>
                </plex-list>

                <!-- Financiador: Obras sociales y prepagas -->
                <ng-container>
                    <div class="mb-4">
                        <plex-title titulo="Cobertura Social" size="sm">
                        </plex-title>
                        <plex-grid type="auto" size="md" cols="1">
                            <seleccionar-financiador [paciente]="paciente"
                                                     (setFinanciador)="setFinanciador($event)"></seleccionar-financiador>
                        </plex-grid>
                    </div>
                </ng-container>

                <!--Información Adicional -->
                <ng-container *ngIf="showInfoAdicional()">
                    <plex-title titulo="Información Adicional" size="sm">
                    </plex-title>

                    <plex-grid type="auto" size="md" cols="{{desplegarOS && obraSocialPaciente?3:1}}">
                        <plex-select *ngIf="desplegarOS" span="2" label="Obra Social" [(ngModel)]="obraSocialPaciente"
                                     name="obraSocialPaciente" (getData)="loadObrasSociales($event)"
                                     labelField="financiador">
                        </plex-select>
                        <plex-text *ngIf="desplegarOS && obraSocialPaciente" label="Número de Afiliado"
                                   name="obraSocialPaciente.numeroAfiliado"
                                   [(ngModel)]="obraSocialPaciente.numeroAfiliado">
                        </plex-text>
                    </plex-grid>
                    <plex-text label="Motivo de consulta / Diagnóstico" name="motivoConsulta"
                               [(ngModel)]="motivoConsulta">
                    </plex-text>
                    <plex-grid type="auto" size="md" cols="2">
                        <plex-phone label="Celular" [(ngModel)]="telefono" (change)="cambiarTelefono($event)"
                                    name="telefono">
                        </plex-phone>
                        <plex-text *ngIf="carpetaEfector" (change)="cambiarCarpeta()" label="Nro Carpeta"
                                   name="nroCarpeta" [(ngModel)]="carpetaEfector.nroCarpeta"></plex-text>
                    </plex-grid>
                    <plex-select *ngIf="verTipoPrestacion()" label="Tipo de prestación"
                                 [(ngModel)]="turnoTipoPrestacion" [data]="bloque.tipoPrestaciones"
                                 name="turnoTipoPrestacion"></plex-select>
                    <plex-text label="Nota" name="nota" [(ngModel)]="nota" (change)="verificarNota()">
                    </plex-text>
                    <plex-text *ngIf="agenda.link" label="Link" name="link" [(ngModel)]="link"></plex-text>
                </ng-container>
                <plex-button *ngIf="estadoT === 'seleccionada'" class="mt-1" justify="end" label="Confirmar"
                             type="success" (click)="darTurno()" [disabled]="!bloque">
                </plex-button>
            </div>
        </ng-container>
    </plex-layout-sidebar>
</plex-layout>