<plex-title size="md" titulo="Editar Agenda">
    <plex-bool [(ngModel)]="editaAgendaPanel.enviarSms" label="Enviar notificación" name="enviarSms" type="slide">
    </plex-bool>
</plex-title>
<plex-grid type="full">
    <!-- Equipo de Salud -->
    <plex-select [(ngModel)]="editaAgendaPanel.profesionales" name="profesionales" tmProfesionales
                 label="Equipo de Salud" placeholder="Seleccione el equipo de salud" closeAfterSelect="true"
                 (change)="validarSolapamientos('profesionales')" [multiple]="true">
    </plex-select>
</plex-grid>
<plex-title size="sm" titulo="Espacio físico">
    <plex-bool [(ngModel)]="espacioFisicoPropio" name="espacioFisicoPropios" label="{{textoEspacio}}"
               (change)="filtrarEspacioFisico()" type="slide">
    </plex-bool>
</plex-title>
<plex-grid type="full">
    <plex-select *ngIf="espacioFisicoPropio" [(ngModel)]="editaAgendaPanel.espacioFisico" name="espacioFisico"
                 (getData)="loadEspacios($event)" label="Espacio Físico" placeholder="Seleccione un espacio"
                 labelField="nombre + ' - ' + servicio.nombre + ' (' + edificio.descripcion + ')'"
                 (change)="validarSolapamientos('espacioFisico')">
    </plex-select>
    <plex-select *ngIf="!espacioFisicoPropio" [(ngModel)]="editaAgendaPanel.otroEspacioFisico" name="otroEspacio"
                 (getData)="loadEspacios($event)" label="Espacio Físico" placeholder="Seleccione un espacio"
                 labelField="nombre">
    </plex-select>
</plex-grid>

<!-- Alertas -->
<div *ngIf="alertas.length > 0" class="alert alert-warning mt-3">
    <div *ngFor="let alert of alertas; let i=index">
        <i class="mdi mdi-alert-outline float-left mr-3"></i>{{alert}}
    </div>
</div>

<filtros-mapa-espacio-fisico *ngIf="showMapa" (onChange)="espaciosChange($event)"></filtros-mapa-espacio-fisico>
<mapa-espacio-fisico *ngIf="showMapa" [agendaSeleccionada]="editaAgendaPanel" [espacioTable]="espaciosList"
                     (onEspacioClick)="selectEspacio($event)">
</mapa-espacio-fisico>