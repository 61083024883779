<plex-layout main="7" *ngIf="!showCreateUpdate && showDashboard && !showDarTurnos">
    <plex-layout-main>
        <!-- BOTONES DE REGISTRO MPI -->

        <paciente-buscar (searchStart)="onSearchStart()" (searchEnd)="onSearchEnd($event.pacientes,$event.scan)"
                         (searchClear)="onSearchClear()" [returnScannedPatient]="true" [hostComponent]="'puntoInicio'"
                         [create]="canCreate">
        </paciente-buscar>

        <plex-loader *ngIf="loading" type="ball-pulse"></plex-loader>

        <!-- LISTA DE RESULTADOS DE LA BÚSQUEDA -->
        <ng-container class="col pr-2" *ngIf="!paciente">
            <paciente-listado *ngIf="resultadoBusqueda && resultadoBusqueda.length" [pacientes]="resultadoBusqueda"
                              (selected)="onPacienteSelected($event)" [offset]="80">
            </paciente-listado>
            <div *ngIf="resultadoBusqueda && !resultadoBusqueda.length && !searchClear" class="alert alert-danger">
                <plex-icon name="account-alert"></plex-icon> No se encontró ningún paciente
            </div>
        </ng-container>

        <!-- VISTA DE PACIENTE SELECCIONADO DE LA LISTA DE RESULTADOS -->
        <div class="col pr-2" *ngIf="paciente">
            <table class="table table-striped">
                <tbody>
                    <tr class="hover">
                        <td (click)="showDatos()">
                            <plex-badge *ngIf="paciente.estado == 'validado'" type="success" class="mr-1">Validado
                            </plex-badge>
                            <plex-badge *ngIf="paciente.estado != 'validado'" type="warning" class="mr-1"
                                        title="Los pacientes temporales sin validar se inactivan luego de un tiempo">
                                Temporal</plex-badge>
                            <plex-badge type="danger" *ngIf="paciente.fechaFallecimiento" class="mr-1"> Fallecido
                            </plex-badge>
                            {{ paciente | nombre }}
                            <br>
                            <b>Documento: </b>
                            <span>{{ paciente | documento }} | </span>
                            <b> Fecha de Nacimiento: </b>
                            <span *ngIf="paciente.fechaNacimiento">
                                {{ paciente.fechaNacimiento | fecha:'utc' }}</span>
                            <span *ngIf="!paciente.fechaNacimiento" class="text-danger">Sin fecha de
                                nacimiento</span>
                            <br>
                            <b>Sexo: </b>
                            <span>{{paciente.sexo}}</span>
                            <ng-container *ngIf="paciente.sexo !== paciente.genero">
                                <br>
                                <b>Identidad de género: </b>
                                <span>{{paciente.genero}}</span>
                            </ng-container>
                        </td>
                        <td class="align-middle">
                            <plex-button *ngIf="puedeDarTurno" type="info" tooltip="Dar Turno" icon="calendar-plus"
                                         (click)="verificarOperacion('darTurno', paciente)">
                            </plex-button>
                        </td>
                        <td class="align-middle">
                            <plex-button *ngIf="puedeCrearSolicitud || puedeVisualizarSolicitud" type="info"
                                         tooltip="Solicitudes" icon="open-in-app"
                                         (click)="verificarOperacion('ingresarSolicitud', paciente)">
                            </plex-button>
                        </td>
                        <td class="align-middle">
                            <plex-button *ngIf="puedeActivarAppMobile" type="info" tooltip="Activar App"
                                         icon="cellphone-android" (click)="verificarOperacion('activarApp', paciente)">
                            </plex-button>
                        </td>
                        <td class="align-middle">
                            <plex-help size="md" cardSize="half" type="help" icon="account-cruz"
                                       tooltip="Demanda insatisfecha">
                                <demandaInsatisfecha (demandaCerrada)="cerrarDemandaInsatisfecha()"
                                                     [paciente]='paciente' *plHelp>
                                </demandaInsatisfecha>
                            </plex-help>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

    </plex-layout-main>

    <!--Panel de Estadisticas y Operaciones-->
    <plex-layout-sidebar>
        <div *ngIf="showMostrarEstadisticasAgendas" type="info">
            <estadisticas-agendas></estadisticas-agendas>
        </div>
        <div *ngIf="showMostrarEstadisticasPacientes && paciente" type="info">
            <estadisticas-pacientes [paciente]="paciente" [showTab]="showTab"
                                    [demandaInsatisfecha]="showDemandaInsatisfecha" (demandaCerrada)="demandaCerrada()">
            </estadisticas-pacientes>
        </div>
        <div *ngIf="showIngresarSolicitud && paciente" type="info">
            <lista-solicitud-turno-ventanilla [paciente]="paciente"
                                              (solicitudPrestacionEmit)="darTurnoSolicitud($event)">
            </lista-solicitud-turno-ventanilla>
        </div>
        <div *ngIf="showActivarApp && paciente" type="info">
            <activar-app *ngIf="paciente?.estado === 'validado'" [paciente]="paciente"></activar-app>
            <plex-label class="pt-4" justify="center" *ngIf="paciente?.estado !== 'validado'" direction="column"
                        icon="account" size="xl" titulo="Activar app mobile"
                        subtitulo="Esta opción está disponible solo para pacientes validados">
            </plex-label>
        </div>
    </plex-layout-sidebar>
</plex-layout>

<!--Components-->
<dar-turnos *ngIf="autorizado && showDarTurnos && paciente" [pacienteSeleccionado]="paciente"
            [solicitudPrestacion]="solicitudPrestacion" (afterDarTurno)="afterDarTurno($event)">
</dar-turnos>