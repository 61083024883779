<plex-title titulo="Detalle" size="sm">
</plex-title>
<plex-detail *ngIf="paciente" [direction]="direction" size="sm" [justify]="justificado">

    <img *ngIf="paciente.foto" [ngClass]="{'img-fallecido' : paciente.fechaFallecimiento}" src="{{ paciente.foto }}">
    <img *ngIf="!paciente.foto && !paciente.id"
         src='data:image/svg+xml;charset=utf-8,<svg%20version%3D"1.1"%20id%3D"Layer_4"%20xmlns%3D"http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg"%20xmlns%3Axlink%3D"http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink"%20x%3D"0px"%20y%3D"0px"%0D%0A%09%20width%3D"480px"%20height%3D"535px"%20viewBox%3D"0%200%20480%20535"%20enable-background%3D"new%200%200%20480%20535"%20xml%3Aspace%3D"preserve">%0D%0A<g%20id%3D"Layer_3">%0D%0A%09<linearGradient%20id%3D"SVGID_1_"%20gradientUnits%3D"userSpaceOnUse"%20x1%3D"240"%20y1%3D"535"%20x2%3D"240"%20y2%3D"4.882812e-04">%0D%0A%09%09<stop%20%20offset%3D"0"%20style%3D"stop-color%3A%23C5C5C5"%2F>%0D%0A%09%09<stop%20%20offset%3D"1"%20style%3D"stop-color%3A%239A9A9A"%2F>%0D%0A%09<%2FlinearGradient>%0D%0A%09<rect%20fill%3D"url%28%23SVGID_1_%29"%20width%3D"480"%20height%3D"535"%2F>%0D%0A<%2Fg>%0D%0A<g%20id%3D"Layer_2">%0D%0A%09<path%20fill%3D"%23FFFFFF"%20d%3D"M347.5%2C250c0%2C59.375-48.125%2C107.5-107.5%2C107.5c-59.375%2C0-107.5-48.125-107.5-107.5%0D%0A%09%09c0-59.375%2C48.125-107.5%2C107.5-107.5C299.375%2C142.5%2C347.5%2C190.625%2C347.5%2C250z"%2F>%0D%0A%09<path%20fill%3D"%23FFFFFF"%20d%3D"M421.194%2C535C413.917%2C424.125%2C335.575%2C336.834%2C240%2C336.834c-95.576%2C0-173.917%2C87.291-181.194%2C198.166%0D%0A%09%09H421.194z"%2F>%0D%0A<%2Fg>%0D%0A<%2Fsvg>'>
    <img *ngIf="!paciente.foto && paciente.id && !paciente.fotoId"
         src='data:image/svg+xml;charset=utf-8,<svg%20version%3D"1.1"%20id%3D"Layer_4"%20xmlns%3D"http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg"%20xmlns%3Axlink%3D"http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink"%20x%3D"0px"%20y%3D"0px"%0D%0A%09%20width%3D"480px"%20height%3D"535px"%20viewBox%3D"0%200%20480%20535"%20enable-background%3D"new%200%200%20480%20535"%20xml%3Aspace%3D"preserve">%0D%0A<g%20id%3D"Layer_3">%0D%0A%09<linearGradient%20id%3D"SVGID_1_"%20gradientUnits%3D"userSpaceOnUse"%20x1%3D"240"%20y1%3D"535"%20x2%3D"240"%20y2%3D"4.882812e-04">%0D%0A%09%09<stop%20%20offset%3D"0"%20style%3D"stop-color%3A%23C5C5C5"%2F>%0D%0A%09%09<stop%20%20offset%3D"1"%20style%3D"stop-color%3A%239A9A9A"%2F>%0D%0A%09<%2FlinearGradient>%0D%0A%09<rect%20fill%3D"url%28%23SVGID_1_%29"%20width%3D"480"%20height%3D"535"%2F>%0D%0A<%2Fg>%0D%0A<g%20id%3D"Layer_2">%0D%0A%09<path%20fill%3D"%23FFFFFF"%20d%3D"M347.5%2C250c0%2C59.375-48.125%2C107.5-107.5%2C107.5c-59.375%2C0-107.5-48.125-107.5-107.5%0D%0A%09%09c0-59.375%2C48.125-107.5%2C107.5-107.5C299.375%2C142.5%2C347.5%2C190.625%2C347.5%2C250z"%2F>%0D%0A%09<path%20fill%3D"%23FFFFFF"%20d%3D"M421.194%2C535C413.917%2C424.125%2C335.575%2C336.834%2C240%2C336.834c-95.576%2C0-173.917%2C87.291-181.194%2C198.166%0D%0A%09%09H421.194z"%2F>%0D%0A<%2Fg>%0D%0A<%2Fsvg>'>
    <img *ngIf="!paciente.foto && paciente.id && paciente.fotoId"
         [ngClass]="{'img-fallecido' : paciente.fechaFallecimiento}" [mpiFotoPaciente]="paciente" alt="">
    <plex-badge [type]="estadoBadgeType" *ngIf="paciente.estado">
        {{ paciente.estado | uppercase }}
    </plex-badge>
    <plex-badge type="danger" *ngIf="paciente.fechaFallecimiento"> Fallecido
    </plex-badge>

    <div title>{{ paciente | nombre }}</div>

    <div subtitle>
        <plex-copy *ngIf="paciente.documento" [value]="paciente.documento">{{ paciente.documento | number }}</plex-copy>
        <plex-copy *ngIf="paciente.numeroIdentificacion" [value]="paciente.numeroIdentificacion">{{
            paciente.numeroIdentificacion }}</plex-copy>
        <span *ngIf="!paciente.numeroIdentificacion && !paciente.documento">Sin DNI</span>
    </div>

    <plex-label *ngIf="paciente.fechaNacimiento" titulo="Fecha de Nacimiento"
                subtitulo="{{ paciente.fechaNacimiento | fecha }}" [direction]="direction"></plex-label>

    <plex-label *ngIf="paciente.fechaFallecimiento" titulo="Fecha de Fallecimiento"
                subtitulo="{{ paciente.fechaFallecimiento | fecha }}" [direction]="direction">
    </plex-label>

    <plex-label *ngIf="paciente.cuil" titulo="CUIL" subtitulo="{{ paciente.cuil}}" [direction]="direction">
    </plex-label>

    <plex-label *ngIf="paciente.fechaNacimiento" titulo="Edad" subtitulo="{{ paciente | edad }}"
                [direction]="direction">
    </plex-label>

    <plex-label *ngIf="paciente.sexo" titulo="Sexo" subtitulo="{{ paciente.sexo.nombre }}" [direction]="direction">
    </plex-label>

    <plex-label *ngIf="paciente.genero" titulo="Identidad de género" subtitulo="{{ paciente.genero.nombre }}"
                [direction]="direction">
    </plex-label>
</plex-detail>

<!-- Pacientes relacionados -->
<ng-container *ngIf="showRelaciones">
    <plex-title titulo="Relaciones" size="sm"></plex-title>
    <plex-list>
        <plex-label *ngIf="!paciente.relaciones?.length" titulo="No hay ninguna relación registrada" subtitulo="">
        </plex-label>

        <plex-item *ngFor="let relacionado of paciente.relaciones; let i = index" class="">
            <img [ngClass]="{'img-fallecido' : relacionado.fechaFallecimiento}"
                 [mpiFotoPaciente]="{ id: relacionado.referencia, fotoId: relacionado.fotoId}" />

            <plex-label *ngIf="!relacionado.numeroIdentificacion" titulo="{{ relacionado | nombre }}"
                        subtitulo="{{ (relacionado.documento | number) || 'Sin DNI' }}">
            </plex-label>

            <plex-label *ngIf="relacionado.numeroIdentificacion" titulo="{{ relacionado | nombre }}"
                        subtitulo="{{ relacionado.numeroIdentificacion }}">
            </plex-label>

            <plex-badge type="info">{{ relacionado?.relacion?.nombre }}
            </plex-badge>

            <plex-badge type="danger" *ngIf="relacionado.fechaFallecimiento"> Fallecido
            </plex-badge>

            <plex-button type="warning" size="sm" icon="pencil" (click)="editRelacion(relacionado)">
            </plex-button>

            <plex-button type="danger" size="sm" icon="close" (click)="removeRelacion(relacionado)">
            </plex-button>
        </plex-item>
    </plex-list>
</ng-container>