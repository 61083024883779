<form #formDemanda="ngForm">
    <plex-title titulo="Demanda Insatisfecha" size="md">
        <plex-button class="float-right" type="success" icon="check" size="sm" (click)="guardar()"
                     [validateForm]="formDemanda" [disabled]="formDemanda.invalid" tooltip="Guardar"
                     tooltipPosition="left">
        </plex-button>
    </plex-title>
    <plex-select label="Tipo de prestación" [(ngModel)]="tipoPrestacion" [required]="true"
                 (getData)="loadTipoPrestaciones($event)" name="tipoPrestacion"
                 placeholder="Buscar una prestación"></plex-select>
    <plex-select [(ngModel)]="profesional" (getData)="loadProfesionales($event)" name="profesionales"
                 label="Profesional" placeholder="Buscar un profesional" labelField="apellido+' '+nombre"
                 [ngModelOptions]="{standalone: true}">
    </plex-select>
    <plex-select label="Motivo" [(ngModel)]="motivo" [required]="true" [data]="motivos" name="motivos"
                 placeholder="Seleccione motivo"></plex-select>
</form>